import { stylesToString } from "~/utils/helpers/stylesToString";

import { getHexColor } from "~/composables/atoms/useColor";
import { Color } from "~/composables/atoms/useColor/types/color";

const createAnchorTag = (
  name: string,
  link: string,
  target: string = "_blank",
  styles: Partial<CSSStyleDeclaration> = { color: getHexColor(Color.BLUE_500) },
) => {
  return `<a class="d-inline-block" target="${target}" href="${link}" style="${stylesToString(styles)}">${name}</a>`;
};

export { createAnchorTag };
