import pickBy from "lodash/pickBy";

import type { EventPayload } from "~/services/analytics/types";
import type { TikTokIdentifyProperties } from "~/services/analytics/destinations/tiktok/types/tikTokIdentifyProperties";

async function stringToSHA256(message: string): Promise<string | undefined> {
  if (!message) {
    return;
  }

  const messageUint8 = new TextEncoder().encode(message);
  const hashBuffer = await window.crypto.subtle.digest("SHA-256", messageUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join(""); // convert bytes to hex string
}

export async function eventPayloadToUserData(payload?: EventPayload | void): Promise<TikTokIdentifyProperties> {
  if (!payload) {
    return;
  }

  const userData: TikTokIdentifyProperties = {
    external_id: await stringToSHA256(payload?.userId || payload?.anonymousId),
    email: await stringToSHA256(payload?.traits?.email),
    phone_number: await stringToSHA256(payload?.traits?.phone),
  };

  return pickBy(userData, (value: string | undefined) => value !== undefined);
}
