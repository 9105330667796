import { AnalyticsEventNames } from "~/mixins/demographics/types/analyticsEventNames";
import { TikTokEventName } from "~/services/analytics/destinations/tiktok/types/tikTokEventName";
import type {
  Contents,
  TikTokEventProperties,
} from "~/services/analytics/destinations/tiktok/types/tikTokEventProperties";
import { EventPayload } from "~/services/analytics/types";
import type {
  InitiateCheckoutEvent,
  CompleteRegistrationEvent,
  ViewContentEvent,
  CompletePaymentEvent,
  SubscribeEvent,
  ScheduleEvent,
} from "~/services/analytics/destinations/tiktok/types/tikTokEvent";

const getContents = (eventPayload?: EventPayload): Contents[] => [
  { content_id: eventPayload?.properties?.version || "checkout", quantity: 1 },
];

const quizProduct: TikTokEventProperties = {
  content_category: "checkout",
  content_name: "Quiz",
  content_type: "product_group",
  contents: getContents(),
  currency: "USD",
  value: 20.0,
};

const initiateCheckoutEventProperties = (eventPayload?: EventPayload): InitiateCheckoutEvent["properties"] => ({
  ...(quizProduct as InitiateCheckoutEvent["properties"]),
  contents: getContents(eventPayload),
  num_items: 1,
  value: 10.0,
});

const leadEventProperties = (eventPayload?: EventPayload): CompletePaymentEvent["properties"] => ({
  ...(quizProduct as CompletePaymentEvent["properties"]),
  contents: getContents(eventPayload),
  value: 30.0,
});

const completeRegistrationEventProperties = (eventPayload?: EventPayload): CompleteRegistrationEvent["properties"] => ({
  ...(quizProduct as CompleteRegistrationEvent["properties"]),
  contents: getContents(eventPayload),
  status: "true",
  value: 40.0,
});

const viewContentEventProperties = (eventPayload?: EventPayload): ViewContentEvent["properties"] => ({
  ...(quizProduct as ViewContentEvent["properties"]),
  contents: getContents(eventPayload),
  value: 1.0,
});

const purchaseEventProperties = (eventPayload?: EventPayload): CompletePaymentEvent["properties"] => ({
  ...(quizProduct as CompletePaymentEvent["properties"]),
  contents: getContents(eventPayload),
  num_items: 1,
  order_id: eventPayload?.properties?.sessionId || undefined,
  value: 100.0,
});

const subscribeEventProperties = (eventPayload?: EventPayload): SubscribeEvent["properties"] => ({
  contents: getContents(eventPayload),
  currency: "USD",
  predicted_ltv: 1000.0,
  price: 1000.0,
  value: 200.0,
});

const scheduleEventProperties = (): ScheduleEvent["properties"] => ({
  currency: "USD",
  value: 60.0,
});

export function eventPayloadToTikTokTrackEvent(
  event: string,
  payload?: EventPayload,
): [eventName: TikTokEventName, payload?: TikTokEventProperties] {
  switch (event) {
    case "Question Viewed":
      if (payload?.properties?.key === "pqokjrjhbc") {
        return [TikTokEventName.VIEW_CONTENT, viewContentEventProperties(payload)];
      }

      if (payload?.properties?.key === "lhdyqmbenx") {
        return [TikTokEventName.COMPLETE_REGISTRATION, completeRegistrationEventProperties(payload)];
      }
      break;
    case TikTokEventName.COMPLETE_REGISTRATION:
      if (payload?.properties?.key !== "lhdyqmbenx") {
        break;
      }

      return [TikTokEventName.COMPLETE_REGISTRATION, completeRegistrationEventProperties(payload)];
    case TikTokEventName.VIEW_CONTENT:
      return [event, viewContentEventProperties(payload)];

    case TikTokEventName.SEARCH:
      return [event, { search_string: payload?.page?.search }];

    case AnalyticsEventNames.CHECKOUT_SESSION_CREATED:
    case TikTokEventName.INITIATE_CHECKOUT:
      return [TikTokEventName.INITIATE_CHECKOUT, initiateCheckoutEventProperties(payload)];

    case AnalyticsEventNames.CHECKOUT_SESSION_COMPLETED:
    case TikTokEventName.PLACE_AN_ORDER:
      return [TikTokEventName.PLACE_AN_ORDER, purchaseEventProperties(payload)];

    case AnalyticsEventNames.SESSION_COMPLETED:
    case TikTokEventName.SUBSCRIBE:
      if (!payload?.properties?.version?.length || !payload?.properties?.version?.includes("medical-history")) {
        break;
      }

      return [TikTokEventName.SUBSCRIBE, subscribeEventProperties(payload)];

    case "Office Hours - Join Now":
    case "Office Hours - Schedule":
    case TikTokEventName.SCHEDULE:
      return [TikTokEventName.SCHEDULE, scheduleEventProperties()];

    case AnalyticsEventNames.CHECKOUT_USER_SIGNED_UP: // TODO: Check event
      return ["Lead" as any, leadEventProperties(payload)];
  }

  return [event as any];
}
