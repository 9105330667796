enum Funnel {
  IMMUNOTHERAPY = "immunotherapy",
  IMMUNOTHERAPY_INSURANCE = "immunotherapy_insurance",
  ALLERGY_TEST = "allergy_test",
  CONSULTATION = "consultation",
  SEVEN_DROPS = "seven_drops",
  FOOD_ALLERGY = "food_allergy",
  CAMP = "camp",
  LACROSSE = "lacrosse",
  FRIENDS_FAMILY = "friendsfamily",
  SAFEGUARD = "safeguard",
  ALLERGY_CHOICES = "allergychoices",
  EPIPEN_ONLY = "epipen_only",
  WEIGHT_LOSS = "weight_loss",
  WEIGHT_LOSS_TIRZEPATIDE = "weight_loss_tirzepatide",
  EMPLOYER_JUNG = "employer_jung",
  EMPLOYER_RLWS = "employer_rlws",
  UNKNOWN = "unknown",
  ANY = "any",
}

export { Funnel };
