import isNil from "lodash/isNil";

import type { EventPayload } from "../../types";
import { Destination } from "../../abstracts/Destination";
import { eventPayloadToTikTokTrackEvent } from "./helpers/eventPayloadToTikTokTrackEvent";
import { eventPayloadToUserData } from "./helpers/eventPayloadToUserData";
import { loadPixel } from "./helpers/loadPixel";

import { getFromQuery } from "~/utils";

export class TikTokDestination extends Destination {
  public constructor(payload?: EventPayload) {
    super({
      id: process?.env?.CX_TIKTOK_PIXEL_ID ?? "CC1RQERC77U9MSBJI870",
      name: "TikTok",
    });

    this.loadIntegration(payload);
  }

  public get isDestinationInstanceReady(): boolean {
    return !!this.id.length && !isNil(window?.ttq);
  }

  public get supplementaryPixels(): string[] {
    return ["CV8VOJRC77UC3LGT5EP0"];
  }

  public initDestination(): void {
    super.initDestination();

    if (this.ready) {
      window.ttq.page();
      this.test();
    }
  }

  public async loadIntegration(_payload?: EventPayload): Promise<void> {
    try {
      await loadPixel(this.id, this.supplementaryPixels);
      this.initDestination();
    } catch (error: unknown) {
      this.logError("Failed to load", error);
    }
  }

  public alias(): void {}

  public identify(payload: EventPayload): void {
    this.enqueue(async () => {
      if (!this.isDestinationInstanceReady) {
        this.logError("Failed to identify", this.id);
        return;
      }

      const userData = await eventPayloadToUserData(payload);

      if (!userData || !Object.values(userData || {}).length) {
        return;
      }

      window.ttq.identify(userData);
    });
  }

  public page(): void {
    this.enqueue(() => {
      if (!this.isDestinationInstanceReady) {
        this.logError("Failed to track page", this.id);
        return;
      }

      window.ttq.page();
    });
  }

  public track(event: string, payload?: EventPayload): void {
    this.enqueue(() => {
      if (!this.isDestinationInstanceReady) {
        this.logError("Failed to track", this.id);
        return;
      }

      const [ttEvent, ttPayload] = eventPayloadToTikTokTrackEvent(event, payload);

      if (event !== ttEvent) {
        window.ttq.track(event);
      }

      window.ttq.track(ttEvent, ttPayload);
    });
  }

  public test(): void {
    this.enqueue(() => {
      if (!this.isDestinationInstanceReady) {
        this.logError("Failed to test", this.id);
        return;
      }

      const testId = getFromQuery("tt_test_id");

      if (!testId) {
        return;
      }

      if (!testId.startsWith(this.id)) {
        this.logError("Invalid tt_test_id", testId, "for", this.id);
        return;
      }

      window.ttq.track("PageView", { contents: [{ content_id: testId, content_name: "Test event" }] });
    });
  }
}
