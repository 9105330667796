import { LoggerService } from "~/services/logger";

const parseResponseAsJson = async (response: Response): Promise<any> => {
  try {
    return await response.clone().json();
  } catch (e) {
    LoggerService.error("parseResponseAsJson", e);
  }

  if (!response?.status) {
    return;
  }

  return {
    data: {
      code: response?.status,
    },
  };
};

export { parseResponseAsJson };
