import { loadScript } from "~/utils/helpers/loadScript";
import { isScriptLoaded } from "~/utils";
import { LoggerService } from "~/services/logger";
import type { TikTokIdentifyProperties } from "~/services/analytics/destinations/tiktok/types/tikTokIdentifyProperties";
import type { TikTokEventProperties } from "~/services/analytics/destinations/tiktok/types/tikTokEventProperties";
import type { TikTokEventName } from "~/services/analytics/destinations/tiktok/types/tikTokEventName";

declare global {
  interface Window {
    ttq?: {
      [key: string]: any;
      identify(payload: TikTokIdentifyProperties): void;
      page(): void;
      track(eventName: string | TikTokEventName, payload?: TikTokEventProperties): void;
      load(pixelID: string): void;

      alias(...args: any[]): void;
      debug(...args: any[]): void;
      disableCookie(...args: any[]): void;
      enableCookie(...args: any[]): void;
      grantConsent(...args: any[]): void;
      group(...args: any[]): void;
      holdConsent(...args: any[]): void;
      instances(...args: any[]): void;
      off(...args: any[]): void;
      on(...args: any[]): void;
      once(...args: any[]): void;
      ready(...args: any[]): void;
      revokeConsent(...args: any[]): void;
    } & Array<any>;
    TiktokAnalyticsObject?: "ttq";
  }
}

const loadPixel = (pixelId: string, additionalPixels: string[] = []): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (!pixelId?.length) {
      LoggerService.error("TikTok pixel id is missing.");
      reject(new Error("TikTok pixel id is missing."));
      return;
    }

    try {
      window.TiktokAnalyticsObject = "ttq";

      const ttq = (window.ttq = window?.ttq || ([] as typeof window.ttq));

      ttq.methods = [
        "page",
        "track",
        "identify",
        "instances",
        "debug",
        "on",
        "off",
        "once",
        "ready",
        "alias",
        "group",
        "enableCookie",
        "disableCookie",
        "holdConsent",
        "revokeConsent",
        "grantConsent",
      ];

      ttq.setAndDefer = function (ttqInst: typeof ttq, method: string) {
        ttqInst[method] = function (...args: any[]) {
          ttqInst.push([method, ...args]);
        };
      };

      ttq.methods.forEach((method: string): void => {
        ttq.setAndDefer(ttq, method);
      });

      ttq.instance = function (ttqId: string) {
        const ttqInstance = ttq._i[ttqId] || [];

        ttq.methods.forEach((method: string): void => {
          ttq.setAndDefer(ttqInstance, method);
        });

        return ttqInstance;
      };

      ttq.load = function (pixelId: string, n?: any): void {
        const tikTokPixelUrl: string = "https://analytics.tiktok.com/i18n/pixel/events.js";
        ttq._i = ttq._i || {};
        ttq._i[pixelId] = [];
        ttq._i[pixelId]._u = tikTokPixelUrl;
        ttq._t = ttq._t || {};
        ttq._t[pixelId] = +new Date();
        ttq._o = ttq._o || {};
        ttq._o[pixelId] = n || {};

        const scriptUrl: string = `${tikTokPixelUrl}?sdkid=${pixelId}&lib=ttq`;

        if (isScriptLoaded(scriptUrl)) {
          LoggerService.warn("TikTok pixel script is already loaded.", tikTokPixelUrl);
          resolve();
          return;
        }

        loadScript(scriptUrl, resolve);
      };

      ttq.load(pixelId);

      additionalPixels.forEach((pixelId: string): void => {
        ttq.load(pixelId);
      });
    } catch (error: unknown) {
      reject(error);
    }
  });
};

export { loadPixel };
