// Source: https://business-api.tiktok.com/portal/docs?id=1739585696931842

// Enum for Standard tiktok events
export enum TikTokEventName {
  /**
   * @description When a page is viewed
   * @summary content_type, quantity, description, content_id, currency, value
   */
  VIEW_CONTENT = "ViewContent",

  /** @description When a button is clicked. */
  CLICK_BUTTON = "ClickButton",

  /** @description When a search is made. */
  SEARCH = "Search",

  /** @descriptionWhen an item is added to a wishlist. */
  ADD_TO_WISHLIST = "AddToWishlist",
  /**
   *  @description When an item is added to the shopping cart.
   *  @summary content_type, quantity, description, content_id, currency, value
   */
  ADD_TO_CART = "AddToCart",

  /** @description When the checkout process is started. */
  INITIATE_CHECKOUT = "InitiateCheckout",

  /** @description When payment information is added in the checkout flow. */
  ADD_PAYMENT_INFO = "AddPaymentInfo",

  /**
   * @description When a payment is completed.
   * @summary content_type, quantity, description, content_id, currency, value
   */
  COMPLETE_PAYMENT = "CompletePayment",

  /**
   * @description When an order is placed.
   * @summary content_type, quantity, description, content_id, currency, value
   */
  PLACE_AN_ORDER = "PlaceAnOrder",

  /** @description When contact or consultation occurs. */
  CONTACT = "Contact",

  /** @description When a button to open an external browser download page is clicked. */
  DOWNLOAD = "Download",

  /** @description When a form is submitted. */
  SUBMIT_FORM = "SubmitForm",

  /** @description When a registration is completed. */
  COMPLETE_REGISTRATION = "CompleteRegistration",

  /** @description When a subscription is made. */
  SUBSCRIBE = "Subscribe",

  /** @description When someone customizes a product. */
  CUSTOMIZE_PRODUCT = "CustomizeProduct",

  /** @description When someone tries to find your location. */
  FIND_LOCATION = "FindLocation",

  /** @description When someone makes an appointment to speak with your business or visit your location. */
  SCHEDULE = "Schedule",
}

export type TikTokEventNameKey = keyof typeof TikTokEventName;
